:root {
  --focus-text-color: #773D30;
  --focus-ring-color: #FDF2E6;
}.base {
  
  
  background-color: #FFFFFF;
}
.alternateGray {
  
  
  background-color: #F5F5F5;
}
.accentDark {
  
  
  background-color: #773D30;
}
.accentLightBordered {
  &:active {
    border-color: #FFFFFF;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: #FDF2E6;
}
.alternateGrayRounded {
  
  
  border-radius: 3px;
  background-color: #F5F5F5;
}
.alternateGrayBordered {
  &:active {
    border-color: #F5F5F5;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #F5F5F5;
  background-color: #F5F5F5;
}
.alternateGrayCircle {
  
  
  border-radius: 100px;
  background-color: #F5F5F5;
}
.accentLight {
  
  
  background-color: #FDF2E6;
}
.baseRounded {
  
  
  border-radius: 10px;
  background-color: #FFFFFF;
}
.accentDarkBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #773D30;
  background-color: #FFFFFF;
}
.accentDarkBorderedRound {
  
  
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #773D30;
  background-color: #FFFFFF;
}
.baseBordered {
  
  
  border-width: 5px;
  border-style: solid;
  border-color: #000000;
  background-color: #FFFFFF;
}
.accentLightLightBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FDF2E6;
}
.baseBorderedRounded {
  
  
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  background-color: #FFFFFF;
}
.accentLightRounded {
  
  
  border-radius: 10px;
  background-color: #FDF2E6;
}
.accentDarkRounded {
  
  
  border-radius: 10px;
  background-color: #773D30;
}
.baseWhiteAlternateGrayBordered {
  &:active {
    border-color: #F5F5F5;
  }
  
  border-width: 2px;
  border-style: solid;
  border-color: #F5F5F5;
  background-color: #FFFFFF;
}
.basePinkCircle {
  
  
  border-radius: 100px;
  background-color: #4E261E;
}
.basePinkRounded {
  
  
  border-radius: 3px;
  background-color: #4E261E;
}
.basePinkBordered {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #4E261E;
  background-color: #FFFFFF;
}
.baseAccentBordered {
  
  
  border-width: 2px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FFFFFF;
}
.baseAccentLightBorderedRound {
  
  
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FFFFFF;
}
.baseAccentLightVeryHeavyBordered {
  
  
  border-width: 5px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FFFFFF;
}
.baseAccentLightHeavyBordered {
  
  
  border-width: 3px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FFFFFF;
}
.baseCircle {
  
  
  border-radius: 100px;
  background-color: #FFFFFF;
}
.baseBorderedCircle {
  
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  background-color: #FFFFFF;
}
.baseAccentLightCircle {
  
  
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: #FDF2E6;
  background-color: #FFFFFF;
}
.accentLightCircle {
  
  
  border-radius: 100px;
  background-color: #FDF2E6;
}.title1 {font-family: "Candal";
  font-size: 1.5rem;
  font-weight: 700;}
.title2 {font-family: "Candal";
  font-size: 1.25rem;
  font-weight: 700;}
.title3 {font-family: "Candal";
  font-size: 1.125rem;
  font-weight: 700;}
.title4 {font-family: "Candal";
  font-size: 1.875rem;
  font-weight: 700;}
.title5 {font-family: "Candal";
  font-size: 0.9375rem;
  font-weight: 700;}
.subtitle1 {font-family: "Open","Sans";
  font-size: 1rem;
  font-weight: 700;}
.subtitle2 {font-family: "Candal";
  font-size: 0.8125rem;
  font-weight: 700;}
.body1 {font-family: "Open","Sans";
  font-size: 0.875rem;
  font-weight: 500;}
.body2 {font-family: "Open","Sans";
  font-size: 1.125rem;
  font-weight: 500;}
.body3 {font-family: "Open","Sans";
  font-size: 0.875rem;
  font-weight: 500;}
.body4 {font-family: "Open","Sans";
  font-size: 1rem;
  font-weight: 500;}
.body5 {font-family: "Open","Sans";
  font-size: 1.25rem;
  font-weight: 500;}.title1_black {@extend .title1;  color: #000000;
  text-transform: uppercase;}
.title1_accentLight {@extend .title1;  color: #FDF2E6;
  text-transform: uppercase;}
.title2_black {@extend .title2;  color: #000000;
  text-transform: uppercase;}
.title2_baseWhite {@extend .title2;  color: #FFFFFF;
  text-transform: uppercase;}
.title2_baseGray {@extend .title2;  color: #989898;
  text-transform: uppercase;}
.title2_accentLight {@extend .title2;  color: #FDF2E6;
  text-transform: uppercase;}
.title2_accentDark {@extend .title2;  color: #773D30;
  text-transform: uppercase;}
.title3_black {@extend .title3;  color: #000000;
  text-transform: uppercase;}
.title5_black {@extend .title5;  color: #000000;
  text-transform: uppercase;}
.title3_accentDark {@extend .title3;  color: #773D30;
  text-transform: uppercase;}
.title3_baseWhite {@extend .title3;  color: #FFFFFF;
  text-transform: uppercase;}
.title4_accentLight {@extend .title4;  color: #FDF2E6;
  text-transform: uppercase;}
.title4_basePink {@extend .title4;  color: #4E261E;
  text-transform: uppercase;}
.title4_accentDark {@extend .title4;  color: #773D30;
  text-transform: uppercase;}
.title5_accentDark {@extend .title5;  color: #773D30;
  text-transform: uppercase;}
.subtitle1_black {@extend .subtitle1;  color: #000000;
  text-transform: initial;}
.subtitle1_accentDark {@extend .subtitle1;  color: #773D30;
  text-transform: initial;}
.subtitle1_baseWhite {@extend .subtitle1;  color: #FFFFFF;
  text-transform: initial;}
.body1_black {@extend .body1;  color: #000000;
  text-transform: initial;}
.body1_accentDark {@extend .body1;  color: #773D30;
  text-transform: initial;}
.body1_baseGray {@extend .body1;  color: #989898;
  text-transform: initial;}
.body1_baseWhite {@extend .body1;  color: #FFFFFF;
  text-transform: initial;}
.subtitle2_baseWhite {@extend .subtitle2;  color: #FFFFFF;
  text-transform: initial;}
.body1_baseLink {@extend .body1;  color: #FFA38B;
  text-transform: initial;}
.body1_accentLight {@extend .body1;  color: #FDF2E6;
  text-transform: initial;}
.body2_black {@extend .body2;  color: #000000;
  text-transform: initial;}
.body2_accentDark {@extend .body2;  color: #773D30;
  text-transform: initial;}
.body2_baseWhite {@extend .body2;  color: #FFFFFF;
  text-transform: initial;}
.body2_baseGray {@extend .body2;  color: #989898;
  text-transform: initial;}
.body2_baseWarning {@extend .body2;  color: #FF2A00;
  text-transform: initial;}
.body2_baseLink {@extend .body2;  color: #FFA38B;
  text-transform: initial;}
.body3_baseWhite {@extend .body3;  color: #FFFFFF;
  text-transform: uppercase;}
.body3_accentDark {@extend .body3;  color: #773D30;
  text-transform: uppercase;}
.body4_accentDark {@extend .body4;  color: #773D30;
  text-transform: initial;}
.body3_black {@extend .body3;  color: #000000;
  text-transform: initial;}
.body4_black {@extend .body4;  color: #000000;
  text-transform: initial;}
.body5_accentDark {@extend .body5;  color: #773D30;
  text-transform: initial;}.buttonPrimaryStandard {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_baseWhite;
@extend .accentLightRounded;
}
.buttonPrimaryAccentDarkStandard {
  &:active {
    @extend .title2_accentLight;
  }
  
  @extend .title2_baseWhite;
@extend .accentDark;
}
.buttonPrimaryStandardBordered {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_black;
@extend .accentLightLightBordered;
}
.buttonPrimaryBasePinkBordered {
  &:active {
    @extend .title2_accentLight;
background-color: #FFFFFF;
  }
  
  @extend .title2_baseWhite;
@extend .basePinkRounded;
}
.buttonPrimaryClear {
  &:active {
    @extend .title2_baseWhite;
background-color: #FDF2E6;
  }
  
  @extend .title2_black;
@extend .base;
}
.buttonPrimaryAccentLightBordered {
  &:active {
    @extend .title2_baseWhite;
background-color: #FDF2E6;
  }
  
  @extend .title2_black;
@extend .baseAccentBordered;
}
.buttonNavStandard {
  &:active {
    @extend .title2_accentDark;
  }
  
  @extend .title2_baseGray;
@extend .basePinkBordered;
}
.buttonNavItemDetail {
  &:active {
    @extend .title2_accentDark;
  }
  
  @extend .title2_baseGray;
@extend .accentDarkBordered;
}
.buttonNavMenu {
  &:active {
    @extend .title2_accentDark;
  }
  
  @extend .title2_baseGray;
@extend .baseBordered;
}
.buttonFooterStandard {
  &:active {
    @extend .title2_baseWhite;
  }
  
  @extend .title2_baseWhite;
@extend .accentDark;
}
.buttonTertiary {
  
  
  @extend .body2_black;
}
.buttonTertiaryBordered {
  &:active {
    @extend .body2_baseWhite;
background-color: #000000;
  }
  
  @extend .body2_black;
@extend .baseBorderedRounded;
}
.buttonTertiaryAccentLight {
  
  
  @extend .body1_accentDark;
}
.buttonTertiaryBlack {
  
  
  @extend .body1_black;
@extend .alternateGray;
}
.buttonTertiaryAccentLightBordered {
  &:active {
    @extend .body1_baseWhite;
background-color: #773D30;
  }
  
  @extend .body1_accentDark;
@extend .baseAccentLightCircle;
}
.buttonTertiaryLink {
  
  
  @extend .body1_baseLink;
}
.buttonTertiaryCircle {
  &:active {
    @extend .body1_baseWhite;
background-color: #FDF2E6;
  }
  
  @extend .body1_accentDark;
@extend .baseCircle;
}
.buttonTertiaryCircleBasePink {
  &:active {
    @extend .subtitle2_baseWhite;
  }
  
  @extend .subtitle2_baseWhite;
@extend .basePinkCircle;
}
.buttonTertiaryCircleAccentLight {
  &:active {
    @extend .subtitle2_baseWhite;
  }
  
  @extend .subtitle2_baseWhite;
@extend .accentLightCircle;
}
.buttonOptionRadio {
  &:active {
    @extend .title3_black;
background-color: #F5F5F5;
  }
  
  @extend .title3_black;
@extend .alternateGrayCircle;
}
.buttonTertiaryRadio {
  &:active {
    @extend .body3_accentDark;
background-color: #FDF2E6;
  }
  
  @extend .body3_accentDark;
@extend .baseAccentLightBorderedRound;
}
.buttonTertiaryTip {
  &:active {
    @extend .body3_baseWhite;
background-color: #773D30;
  }
  
  @extend .body3_black;
@extend .accentDarkBorderedRound;
}.primary {
@extend .subtitle1_accentDark;
 &::placeholder {
    @extend .body1_baseGray;
  }
}
.secondary {
@extend .subtitle1_accentDark;
 &::placeholder {
    @extend .body1_baseGray;
  }
}